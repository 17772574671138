import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import BarcodeLookupView from "../views/BarcodeLookupView";
import BarcodeSearchView from "../views/BarcodeSearchView";
import AppFooter from "./AppFooter";
import RedirectComponent from "../components/grading/RedirectComponent";

interface AppRoutingProps {
  children: React.ReactChild | React.ReactChildren;
}

function AppRouting(props: AppRoutingProps) {
  const { children } = props;

  return (
    <BrowserRouter>
      <div className="background" />
      {children}
      <div className="content">
        <Routes>
            <Route path="/" element={<RedirectComponent />} />
            <Route path="/test" element={<RedirectComponent />} />
          <Route path="/report/:barcode" element={<RedirectComponent />} />
        </Routes>
      </div>
      <AppFooter />
    </BrowserRouter>
  );
}

export default AppRouting;
