import React from 'react';
import './App.css';
import {Row, Col} from "react-bootstrap";

function AppFooter() {
  return (
      <div className="footer mt-5 p-4 fixed-bottom bg-silver">
          <Col className="d-flex flex-row justify-content-around">
              <Row className="d-flex align-items-center fw-bold justify-content-center text-white">©{ new Date().getFullYear() } All rights reserved - Premier Card Grading</Row>
              <Row className="d-flex align-items-center justify-content-center">
                  <a className="text-white text-decoration-none" href="https://premiercardgrading.com/policies/privacy-policy">Privacy Policy</a>
                  <a className="text-white text-decoration-none" href="https://premiercardgrading.com/policies/terms-of-service">Terms of Service</a>
              </Row>
          </Col>
      </div>
  );
}
export default AppFooter;
