import AppRouting from "./AppRouting";
import AppHeader from "./AppHeader";
import ApiProvider from "../providers/ApiProvider";
import {Container} from "react-bootstrap";

function App() {
  return (
      <ApiProvider>
          <Container>
            <AppRouting>
              <AppHeader />
            </AppRouting>
          </Container>
      </ApiProvider>
  );
}

export default App;
