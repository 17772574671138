import React from 'react';
import {useParams} from "react-router-dom";

type RouterProps = {
    barcode?: string;
}

function RedirectComponent() {
    const { barcode } = useParams<RouterProps>();

    if (!barcode || barcode.length < 1) {
        window.location.assign("https://www.pcgpopreport.com/")

    } else {
        window.location.assign("https://www.pcgpopreport.com/report/" + barcode)
    }

    return (
        <>

        </>
    );
}

export default RedirectComponent;