import React from 'react';
import './App.css';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";

function AppHeader() {
  return (
      <div className="header">
        <Navbar expand="lg" fixed="top" bg="dark" variant="dark">
          <Container>
            <Navbar.Brand href="#home">
              <img
                  src="/pcg-logo-05b.png"
                  width="80"
                  height="40"
                  className="d-inline-block align-top"
                  alt="Premier Card Grading"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/">Barcode Lookup & Validation</Nav.Link>
              </Nav>
              <Nav>
                <NavDropdown title="Grading Countries" id="basic-nav-dropdown">
                  <NavDropdown.Item target="_blank" href="https://www.premiercardgrading.com">United
                    States</NavDropdown.Item>
                  <NavDropdown.Item target="_blank" href="https://www.premiercardgrading.co.nz">New
                    Zealand</NavDropdown.Item>
                  <NavDropdown.Item target="_blank"
                                    href="https://www.premiercardgrading.com.au">Australia</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
  );
}

export default AppHeader;
